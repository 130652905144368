.home {
  color: #fff;
  .login-container {
    font-size: 0.8125rem;
    max-width: 33.625rem;

    .btn {
      min-width: 6.875rem;
    }
    .logo {
      fill: $white;
      height: 2.5rem;
      width: auto;
    }
  }
}

@media (max-width: 560px) {
  .home {
    .login-container {
      .card-body {
        div {
          &:last-child {
            display: flex;
            flex-direction: column;
            align-items: center;
            button {
              margin: 16px 0;
            }
          }
        }
      }
    }
  }
}
